@import url(https://fonts.googleapis.com/css2?family=Anton&family=Montaga&family=Poppins&display=swap);
:root {
  --background-color: white;
  --primary-color: #1c3956;
  --secundary-color: #fbd77b;
  --primary-text-color: #af8f61;
  --secundary-text-color: black;
  --share-background-color: #dfddd0;
  --head-text-font: 'Poppins';
  --head-text-size: 36px;
  --head-text-color: #ec8c1c;
  --p-text-font: 'Poppins';
  --p-text-size: 18px;
  --subhead-text-color: #2245bc;
  --cards-text-font: 'Poppins';
  --cards-text-color: #4b4b4b;
  --cards-text-size: 16px;
  --color-black: #000000;
  --new-background-color: #f6f6f6;
  --secondary-blue-color: #042cb4;
}

body {
  margin: 0;
  font-family: 'Poppins' !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: Poppins;
}

body::-webkit-scrollbar {
  width: 12px; /* width of the entire scrollbar */
}
body::-webkit-scrollbar-track {
  background: black; /* color of the tracking area */
}
body::-webkit-scrollbar-thumb {
  background-color: rgba(255, 255, 255, 0.6); /* color of the scroll thumb */
  border-radius: 20px; /* roundness of the scroll thumb */
  border: 1px solid black; /* creates padding around scroll thumb */
}

.slide-enter,
.slide-exit {
  transition: transform 1000ms ease-out;
}

.slide-enter {
  transform: translateY(100%);
}

.slide-enter.slide-enter-active {
  transform: translateY(0%);
}

.slide-exit {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  transform: translateY(0%);
}

.slide-exit-active {
  transform: translateY(-100%);
}

