.slide-enter,
.slide-exit {
  transition: transform 1000ms ease-out;
}

.slide-enter {
  transform: translateY(100%);
}

.slide-enter.slide-enter-active {
  transform: translateY(0%);
}

.slide-exit {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  transform: translateY(0%);
}

.slide-exit-active {
  transform: translateY(-100%);
}
